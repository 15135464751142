import useClient from '@sprinx/react-api-client/useClient';
// @ts-ignore
import AuthHandler from '@sprinx/react-auth/AuthHandler';
import PropTypes from 'prop-types';
import React from 'react';

const resolveAuth = (client, credentials) => {
  // eslint-disable-next-line no-console
  return client.call('v1.auth.login', credentials, { method: 'post', mockAction: 'login' });
};

function AppAuthHandler({ registerOnAuthenticate, registerOnLogout }) {
  const client = useClient('api');
  return (
    <AuthHandler
      client={client}
      onAuth={resolveAuth}
      // onAuthData={() => Promise.resolve({})}
      // onMeta={() => Promise.resolve({})}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onLogout={() => {}}
      // onScope={() => {}}
      registerOnAuthenticate={[registerOnAuthenticate, 0]}
      registerOnLogout={registerOnLogout}
    />
  );
}

AppAuthHandler.propTypes = {
  registerOnAuthenticate: PropTypes.func.isRequired,
  registerOnLogout: PropTypes.func.isRequired,
};

export default AppAuthHandler;
