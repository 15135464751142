const messages = {
  de: {
    app: {
      about: 'Über Anwendung',
      actions: {
        add: 'Hinzufügen',
        addNamed: '{name} hinzufügen',
        remove: 'Löschen',
        removeNamed: 'lösche {name}',
        removeConfirmation: 'Möchten Sie dies wirklich löschen?',
        more: 'Mehr',
        less: 'Weniger',
        download: 'Herunterladen',
        save: 'Auferlegen',
        cancel: 'Stornieren',
        close: 'Schließen',
        moreFormFields: 'Ein anderes Feld',
        export: 'Export',
        edit: 'Bearbeiten',
        createdSuccessMessage: 'Erfolgreich erstellt.',
        savedSuccessMessage: 'Erfolgreich gespeichert.',
        deletedSuccessMessage: 'Erfolgreich gelöscht.',
        detail: 'Detail',
        next: 'Nächste',
        back: 'Zurück',
        start: 'Anfang',
        whatNext: 'Wo jetzt',
        confirm: 'Bestätigen',
        confirmRemove: 'Möchten Sie dies wirklich löschen?',
        search: 'Suchen',
        userProfile: 'Benutzerprofil',
        tenantProfile: 'Ihr CRM-Profil',
        imports: 'Importieren',
        fileImports: 'Dateien importieren',
        settings: 'Einstellungen',
        logout: 'Ausloggen',
        login: 'Anmeldung',
        filterList: 'Filter',
        excel: 'Excel',
        refresh: 'Zurücksetzen',
        clear: 'Löschen',
        agree: 'Ich stimme zu',
        disagree: 'Ich bin nicht einverstanden',
        makeCopy: 'Kopieren',
        fullscreen: 'Im Vollbild anzeigen',
        fullscreenClose: 'Vollbildansicht beenden',
        copy: 'Kopieren',
      },
      messages: {
        confirmRemove: 'Möchten Sie dieses Produkt wirklich löschen?',
        errorOccurred: 'Ein Fehler ist aufgetreten',
        createdSuccess: 'Die Erstellung war erfolgreich.',
        savedSuccess: 'Das Speichern war erfolgreich.',
        deletedSuccess: 'Die Löschung war erfolgreich.',
        uploadSuccess: 'Datei erfolgreich hochgeladen.',
        uploadError: 'Fehler beim Herunterladen der Datei.',
        uploadInProgress: 'Wird geladen...',
        uploadedFiles:
          '{number, plural, one {1 Datei hochgeladen} few {{number} Dateien hochgeladen} other {{number} Dateien hochgeladen}}',
      },
      error: 'Fehler!',
      validations: {
        isRequired: 'Das Feld {field} ist erforderlich.',
        minLengthIsRequired:
          'Mindestens {number, plural, one {1 item} few {{number} items} other {{number} Produkte}} sind erforderlich.',
        minValue: 'Der zulässige Mindestwert ist {value}.',
        maxValue: 'Der maximal zulässige Wert ist {value}.',
        passwordMatchError: 'Passwörter stimmen nicht überein',
        invalidEmail: 'Die E-Mail ist ungültig.',
        invalidPhoneNumber: "Die Telefonnummer muss ein gültiges internationales Format haben! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Ungültige Nummer',
        invalidUrl: 'Ungültige URL.',
        positive: 'Die Zahl muss einen positiven Wert haben.',
        negative: 'Die Zahl muss negativ sein.',
      },
      login: {
        title: 'Melden Sie sich bei Sprinx CRM an',
        username: 'Nutzername',
        password: 'Passwort',
        loginButton: 'Anmeldung',
        error: 'Anmeldung war nicht erfolgreich. Benutzername oder Passwort nicht gefunden',
        pleaseRelogin: 'Anscheinend bist du nicht eingeloggt. Bitte versuchen Sie erneut, sich anzumelden.',
      },
      days: '{count, plural, one {1 Tag} few {{formattedCount} Tage} other {{formattedCount} Tage}}',
      overdue: 'Verspätung {count, plural, one {1 Tag} einige {{formattedCount} Tage} other {{formattedCount} Tage}}',
      newItem: 'Neu',
      anonymizing: {
        confirm: {
          title: 'Anonymisierung bestätigen',
          content: 'Sind Sie sicher, dass Sie personenbezogene Daten anonymisieren möchten?',
        },
        button: 'Anonymisierung personenbezogener Daten',
      },
      appMenu: {
        discover: 'Entdecken',
      },
      missingType: 'Bitte geben Sie zunächst {typeName} in der Anwendungsverwaltung ein.',
      missingTypeForEntityType:
        'Bitte definieren Sie zuerst {typeName} für {forEntityTypeName} in der Anwendungsverwaltung.',
      emptyScoringBlog:
        'Module, deren Inhalte oder Einstellungen auf dieser Seite angezeigt werden, sind nicht aktiviert.',
    },
    components: {
      buttons: {
        showOnMap: 'Auf der Karte anzeigen',
      },
      filters: {
        filterButtonSubmit: 'Suchen',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Fügen Sie eine Bedingung hinzu',
        resetFilter: 'Filter abbrechen',
        operators: {
          contains: ['enthält'],
          containsexact: ['genau enthält'],
          notcontains: ['beinhaltet nicht'],
          betweennumber: ['aus', 'Zu'],
          betweendate: ['aus', 'Zu'],
          equal: ['ist dasselbe'],
        },
      },
      paginationList: {
        itemsTotal: 'Gesamtzahl der Produkte: {number}',
        nextPage: 'Produktliste: {number}',
        emptyMessage: 'Keine Produkte gefunden.',
      },
      selects: {
        emptyOption: 'Leer',
        emptyMessage: 'Keine Produkte gefunden.',
      },
      tables: {
        labelRowsPerPage: 'Eine Zeile auf einer Seite',
        sortBy: 'Sortieren nach {field}',
        recordsChanged: 'Einige Einträge wurden hinzugefügt oder geändert.',
      },
      textField: {
        errorMsgWrapper: '',
      },
      dateField: {
        invalidDateFormat: 'Falsches Datumsformat.',
        invalidDate: 'Falsches Datum.',
        maxDateMessage: 'Das Datum kann nicht größer als das maximale Datum sein',
        minDateMessage: 'Das Datum darf nicht kleiner als das Mindestdatum sein',
        today: 'Heute',
      },
      detailNavigation: {
        scoringblog: 'Scoring-Blog',
        card: 'Karte',
        relations: 'Sei',
      },
      manipulationInfo: {
        created: 'Erstellt',
        updated: 'Geändert',
        removed: 'Gelöscht',
      },
    },
  },
};
export default messages;
