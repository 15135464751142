const messages = {
  de: {
    reactMuiTables: {
      selected: 'gewählt',
      filterhide: 'Filter ausblenden',
      filtershow: 'Filter anzeigen',
      refresh: 'Wiederherstellen',
      filter: {
        actions: {
          search: 'Suchen',
          resetFilter: 'Filter löschen',
          addCondition: 'Fügen Sie eine Bedingung hinzu',
        },
      },
      standardOperators: {
        equals: 'gleich',
        contains: 'enthält',
        between: {
          label: 'zwischen',
          from: 'aus',
          to: 'zu',
        },
        oneOf: 'einer von',
      },
      labelRowsPerPage: 'Anzahl Zeilen pro Seite:',
    },
  },
};
export default messages;
