import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import clsx from 'clsx';
import React from 'react';
import { Helmet } from 'react-helmet';

export interface PageProps extends StandardDivProps<PageClassKey> {
  breadcrumbs?: React.ReactNode;
  children?: React.ReactNode;
  pageTitle: React.ReactNode;
}

export type PageClassKey = 'root' | 'breadcrumbs' | 'content';

const themeSettings = { name: 'SxWebPage' };
const useStyles = makeStyles(
  createStyles<PageClassKey, {}>({
    root: {
      width: '100%',
    },
    breadcrumbs: {
      padding: 0,
    },
    content: {},
  }),
  themeSettings,
);

const Page: React.FC<PageProps> = ({ breadcrumbs, classes: pClasses, className, children, pageTitle, ...rest }) => {
  const classes = useStyles({ classes: pClasses });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {breadcrumbs && <div className={classes.breadcrumbs}>{breadcrumbs}</div>}
      <div className={classes.content}>{children}</div>
    </div>
  );
};

Page.displayName = 'Page';

export default Page;
