import createSubjectIndirectSubjects from '@sprinx/pricing-core/createSubjectIndirectSubjects';
import resolveAndCallStrategy from '@sprinx/pricing-core/resolveAndCallStrategy';
import calcPercentDiscountPrice from '@sprinx/pricing-core/utils/calcPercentDiscountPrice';
import pipeP from '@sprinx/pricing-core/utils/pipeP';
import ruleApplicable from '@sprinx/pricing-core/utils/ruleApplicable';

export default async function mapSubjectIndirectPercentDiscountFromTotal(
  ctx,
  rule,
  subject,
  targets,
  innerCondition,
  percentDiscount,
  discountProduct,
) {
  const cart = JSON.parse(window.localStorage.getItem('shopping-cart')) || {};
  const prevPrice = subject.subjectTotal;
  const discPrice = await calcPercentDiscountPrice(prevPrice, percentDiscount);

  const enteredPromoCodes = cart.promoCodes || [];

  const checkPromoCode = async (x) => {
    const promoCode = targets?.[0]?.promoCode;
    return promoCode?.length > 0 ? enteredPromoCodes.includes(promoCode) : x;
  };

  const result = await pipeP(
    async (subjects) => subjects.filter((s) => !s.ruleFootprint),
    async (subjects) => Promise.all(subjects.map(ruleApplicable(ctx, { targets }))),
    async (applicable) => applicable.reduce((r, [e, isa, sbj]) => (!e && isa ? [...r, sbj] : r), []),
    async (applicableSubjects) =>
      resolveAndCallStrategy(ctx, 'innerCondition', innerCondition, { subjects: applicableSubjects }),
    async (isPromoEntered) => checkPromoCode(isPromoEntered),
    async (isConditionTrue) =>
      createSubjectIndirectSubjects(ctx, rule, isConditionTrue ? discountProduct : null, discPrice - prevPrice),
  )(ctx.cart.subjects);

  return {
    ...result,
  };
}
