import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@sprinx/react-mui-components/Link';
import logo from '../../icons/logo.png';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 277,
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 220,
        height: 68,
      },
      [theme.breakpoints.down('xs')]: {
        width: 128,
        height: 40,
      },
    },
    logo: {
      width: 277,
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 220,
      },
      [theme.breakpoints.down('xs')]: {
        width: 128,
      },
    },
  }),
  { name: 'SxMainLogo' },
);

function MainLogo({ classes: pClasses, className, ...rest }) {
  const classes = useStyles({ classes: pClasses });

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Link to={'/'}>
        <img src={logo} className={classes.logo} alt='logo' />
      </Link>
    </div>
  );
}

MainLogo.propTypes = {
  classes: PropTypes.shape({
    logo: PropTypes.string,
    root: PropTypes.string,
  }),
  className: PropTypes.string,
};

MainLogo.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default MainLogo;
