// @ts-ignore
import ErrorPageLayout from '@sprinx/cvo-eshop-ui-common/pages/ErrorPage/ErrorPageLayout';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import React from 'react';
import { Route } from 'react-router-dom';
import Page from '../../components/Page';

export default function InternalErrorPage({ error }: { error?: any }): JSX.Element {
  const t = useTranslate();
  const logError = (...args: any[]) => {
    console.error('Internal Error', ...args);
  };

  if (error instanceof Error) {
    logError(error.message, error);
  } else {
    logError(error);
  }
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 500;
        return (
          <Page pageTitle={t('errorPage/title')}>
            <ErrorPageLayout />
          </Page>
        );
      }}
    />
  );
}
