const messages = {
  de: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Korb',
        },
      },
      productDetail: {
        parameters: {
          title: 'Parameter',
        },
      },
      productAvailability: {
        format: {
          available: 'Auf Lager',
          moreThanX: 'Mehr als {min} Stück auf Lager',
          exactCount: '{value} Stück auf Lager',
          notAvailable: 'Nicht verfügbar',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ mehr ({number}).',
      },
      relatedProducts: {
        title: 'Verwandte Produkte',
      },
      productAccessories: {
        title: 'Zubehör',
      },
      productDetailImageGallery: {
        noImage: 'Keine Bilder',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Wann erhalte ich die Ware?',
      },
    },
  },
};
export default messages;
