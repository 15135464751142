import PropTypes from 'prop-types';
import messages from './de';
import comOrdersMessages from './react-com-orders/de';
import comMessages from './react-com/de';
import formsMessages from './react-forms/de';
import compMessages from './react-mui-components/de';
import tablesMessages from './react-mui-tables/de';
import shoppingCartMessages from './react-shopping-cart/de';

// const rename = ({ sr }) => ({ hr: sr });

function MessagesFr({ children }) {
  return children(
    [
      compMessages,
      // fieldMessages,
      tablesMessages,
      formsMessages,
      // pagesMessages,
      comMessages,
      // shoppingListMessages,
      shoppingCartMessages,
      comOrdersMessages,
      // invoicesMessages,
      messages,
    ], // .map(rename),
  );
}

MessagesFr.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesFr;
