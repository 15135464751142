import useSnackbar from '@sprinx/react-mui-layout/useSnackbar';
import PropTypes from 'prop-types';
import React from 'react';
import ApiCallProvider from './ApiCallProvider';
import apiUri from './apiUri';
import AppRestClientClass from './AppRestClientClass';

const clients = {};

const initClients = (clientCode, accessToken) => {
  if (!clients[clientCode]) {
    clients[clientCode] = new AppRestClientClass({
      clientCode,
      hostUri: apiUri,
      secureClientId: process.env.RAZZLE_AUTH_CLIENT_ID,
      accessToken,
    });
  }

  return clients;
};

function AppConnectServers({ accessToken, children }) {
  const clientCode = 'api';
  const [showMessage] = useSnackbar();
  initClients(clientCode, accessToken);
  React.useEffect(() => {
    if (clients[clientCode]) {
      const cl = clients[clientCode];
      if (cl.setAccessToken) cl.setAccessToken(accessToken);
    }
  }, [accessToken]);

  return (
    <ApiCallProvider clients={clients} showMessage={showMessage}>
      {children}
    </ApiCallProvider>
  );
}

AppConnectServers.propTypes = {
  accessToken: PropTypes.string,
  children: PropTypes.node,
};

AppConnectServers.defaultProps = {
  accessToken: undefined,
  children: null,
};

export default AppConnectServers;
