import ErrorPage from './pages/ErrorPage';
import NotFoundPage from './pages/NotFoundPage';
import { routeAllRoutesToRouter } from './routesBuilder';

export default [
  ...routeAllRoutesToRouter(),
  {
    path: '/_error',
    component: ErrorPage,
  },
  {
    component: NotFoundPage,
  },
];
