const messages = {
  de: {
    orders: {
      actions: {
        addNewOrder: 'Neue Bestellung',
        all: 'Alle Bestellungen',
      },
      listPage: {
        title: 'Aufträge',
        table: {
          filter: {
            number: 'Nummer',
          },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Datum der Bestellung',
            number: 'Nummer',
            offer: 'Angebot',
            opportunity: 'Gelegenheit',
            owner: 'Eigentümer',
            state: 'Erde',
            total: 'In Summe',
            totalUserCurrency: 'Gesamt in {currency}',
          },
        },
      },
      order: {
        newEntity: 'Neue Bestellung',
        contact: {
          label: 'Kontakt',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Gesamtprovision',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Währung',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Datum der Bestellung',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Nummer',
          helperText: 'Lassen Sie das Feld leer, um die Nummer automatisch zu vervollständigen.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Angebot',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Gelegenheit',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Eigentümer',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Rand',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Name',
          discount: {
            label: 'Rabatt',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Kommission',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Verpackung',
          },
          listPrice: {
            label: 'Listenpreis',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Preis',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Produkt',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Menge',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Rand',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'In Summe',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Stk',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'In Summe',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {keine Produkte} one {1 Produkt} few {{number} Produkte} other {{number} Produkte}}',
      },
      myOrdersScoringBlog: {
        title: 'Meine Bestellungen',
      },
      ordersList: 'Aufträge',
    },
  },
};
export default messages;
