import RestClientClass from '@sprinx/react-api-client/clients/restClientClass';
import axios from 'axios';
import qs from 'qs';

export default class AppRestClientClass extends RestClientClass {
  constructor(options) {
    super({ ...options, realtime: false });
    this.app = axios.create({
      baseURL: this.hostUri,
      timeout: 5000,
      paramsSerializer: (params) => qs.stringify(params),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  connect = async () => {};
}
