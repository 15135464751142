import ApiCallContext from '@sprinx/react-api-client/ApiCallProvider/ApiCallContext';
import PropTypes from 'prop-types';
import React from 'react';

function ApiCallProvider({ children, clients, showMessage }) {
  return (
    <ApiCallContext.Provider
      value={{
        clients,
        showMessage,
      }}
    >
      {children}
    </ApiCallContext.Provider>
  );
}

ApiCallProvider.propTypes = {
  children: PropTypes.node,
  clients: PropTypes.shape({
    api: PropTypes.shape({}),
  }).isRequired,
  /**
   * Funkce pro zobrazeni hlasek z API. Snackbar, toaster, nebo jiny nastroj. Pokud neni definovan, bude nahrazen
   * dumy funkci na zalogovani.
   */
  showMessage: PropTypes.func,
};

ApiCallProvider.defaultProps = {
  children: null,
  showMessage: undefined,
};

export default ApiCallProvider;
