const messages = {
  de: {
    sprinxFormsValidation: {
      required: 'Feld ist erforderlich!',
      string: 'Es muss eine Textzeichenfolge sein!',
      stringEmpty: 'Es darf nicht leer sein',
      stringMin: 'Die Länge muss größer oder gleich {expected} Zeichen sein!',
      stringMax: 'Die Länge muss kleiner oder gleich {expected} Zeichen sein!',
      stringLength: 'Die Länge muss {expected} Zeichen betragen!',
      stringPattern: 'Entspricht nicht dem gewünschten Muster!',
      stringContains: "Der Text '{expected}' muss enthalten sein!",
      stringEnum: 'Entspricht keinem der erlaubten Werte!',
      stringNumeric: 'Das muss die Nummer sein!',
      number: 'Das muss die Nummer sein!',
      numberMin: 'Muss größer oder gleich {expected} sein!',
      numberMax: 'Muss kleiner oder gleich {expected} sein!',
      numberEqual: 'Muss mit {expected} identisch sein!',
      numberNotEqual: 'Darf nicht gleich {expected} sein!',
      numberInteger: 'Muss eine ganze Zahl sein!',
      numberPositive: 'Die Zahl muss einen positiven Wert haben!',
      numberNegative: 'Die Zahl muss negativ sein.',
      array: 'Muss eine Liste sein!',
      arrayEmpty: 'Darf keine leere Liste sein!',
      arrayMin:
        'Es muss mindestens {number, plural, one {1 Produkt} few {{number} Products} other {{number} Produkts}} geben!',
      arrayMax:
        'Kann nicht mehr als {number, plural, one {1 Produkt} few {{number} Products} other {{number} Produkts}} enthalten!',
      arrayLength:
        'Es muss genau {expected, plural, one {1 Produkt} few {{number} Products} other {{number} Produkts}} enthalten!',
      arrayContains: "Muss Produkt '{expected}' enthalten!",
      arrayEnum: "Der Wert '{expected}' stimmt mit keinem der erlaubten Werte überein!",
      boolean: 'Es muss "ja" aber "nein" sein!',
      function: 'Muss ein Feature sein!',
      date: 'Muss ein Date sein!',
      dateMin: 'Muss größer oder gleich {expected} sein!',
      dateMax: 'Muss kleiner oder gleich {expected} sein!',
      forbidden: 'Verboten!',
      email: 'E-Mail ist richtig!',
      url: 'Ungültige URL!',
      object: 'Es muss vom Typ Objekt sein!',
      arrayReducedEquals: 'Gesamtwert stimmt nicht mit {expected} überein!',
      phoneNumber: "Die Telefonnummer muss ein gültiges internationales Format haben! '+ XXX XXX XXX XXX '!",
      dateGt: 'Das Datum muss größer als {expected} sein!',
      dateGte: 'Das Datum muss größer oder gleich {expected} sein!',
      dateLt: 'Das Datum muss kleiner als {expected} sein!',
      dateLte: 'Das Datum muss kleiner oder gleich {expected} sein!',
    },
  },
};
export default messages;
