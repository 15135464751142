import pricingRulesDataStatic from '../pricingRulesData';

export default function getPricingRulesData() {
  const { pricingRules, pricingRulesCompiled } = pricingRulesDataStatic;
  try {
    const { commonThings } = (typeof window !== 'undefined' && window) || global;
    const { pr } = commonThings;
    const pricingRulesDb = JSON.parse(Buffer.from(pr, 'base64').toString());
    return {
      pricingRules: [...pricingRules, ...pricingRulesDb],
      pricingRulesCompiled: [[-2, pricingRulesDb], ...pricingRulesCompiled],
    };
  } catch {
    return pricingRulesDataStatic;
  }
}
