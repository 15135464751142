import React from 'react';
import Portal from '@material-ui/core/Portal';
import { withStyles } from '@material-ui/core/styles';
import MuiLinearProgress from '@material-ui/core/LinearProgress';

const LinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#00FF40',
    transition: 'none',
  },
}))(MuiLinearProgress);

const AppComponentLoader: React.FC = () => {
  return (
    <Portal>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 10000 }}>
        <LinearProgress />
      </div>
    </Portal>
  );
};

AppComponentLoader.displayName = 'ComponentLoader';

export default AppComponentLoader;
