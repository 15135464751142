import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import FooterMenu from '@sprinx/cvo-eshop-ui-common/components/FooterMenu';
import Newsletter from '@sprinx/cvo-eshop-ui-common/components/Newsletter';
import clsx from 'clsx';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';
import MainLogo from '../MainLogo';
import FooterPartners from './FooterPartners';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme.palette.background.default,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      minHeight: 'unset',
      flexDirection: 'column',
    },
    container: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  }),
  { name: 'SxComFooter' },
);

function Footer({ classes: pClasses, className, hideFooterItems, footerItems, maxWidth, phoneData, ...rest }) {
  const classes = useStyles({ classes: pClasses });
  return (
    <div {...pickHTMLProps(rest)} className={clsx(classes.root, className)}>
      <Container maxWidth={maxWidth} className={classes.container}>
        <Newsletter phoneNumber={phoneData} MainLogo={MainLogo} />
        <FooterPartners />
        <FooterMenu hideFooterItems={hideFooterItems} footerItems={footerItems} />
      </Container>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.shape({
    column: PropTypes.string,
    container: PropTypes.string,
    links: PropTypes.string,
    root: PropTypes.string,
    row: PropTypes.string,
    typo: PropTypes.string,
  }),
  className: PropTypes.string,
  footerItems: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, href: PropTypes.string })),
  phoneData: PropTypes.string,
  hideFooterItems: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
};

Footer.defaultProps = {
  classes: undefined,
  className: undefined,
  footerItems: undefined,
  phoneData: undefined,
  hideFooterItems: false,
  maxWidth: false,
};

export default Footer;
