// @ts-ignore
import NotFoundPageLayout from '@sprinx/cvo-eshop-ui-common/pages/NotFoundPage/NotFoundPageLayout';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import React from 'react';
import { Route } from 'react-router-dom';
import Page from '../../components/Page';

export type NotFoundErrorPageProps = StandardDivProps<NotFoundErrorPageClassKey>;

export type NotFoundErrorPageClassKey = 'root' | 'content' | 'title' | 'button';

const themeSettings = { name: 'NotFoundErrorPage' };

function NotFoundErrorPage(): JSX.Element {
  const t = useTranslate();

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <Page pageTitle={t('page404/title')}>
            <NotFoundPageLayout />
          </Page>
        );
      }}
    />
  );
}

export default withThemeProps(themeSettings)(NotFoundErrorPage);
