const messages = {
  de: {
    shoppingCart: {
      actions: {
        addToCart: 'in den Warenkorb',
        buy: 'Besorgen',
        order: 'Befehl',
        remove: 'Entfernen',
      },
      cart: {
        total: {
          label: 'In Summe',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'In Summe',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'In Summe',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Gesamtbetrag',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'MwSt',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Warenkorb zurücksetzen',
      },
      messages: {
        confirmResetCart: 'Möchten Sie den Inhalt des Warenkorbs wirklich löschen?',
        giftClaim: 'Sie können {quantity} Stücke von {productName} als Geschenk erhalten.',
        cartManipulations: {
          allRemoved: 'Alle Elemente wurden entfernt.',
          itemAdded:
            '{number, plural, one {Artikel zum Warenkorb hinzugefügt} other {Artikel zum Warenkorb hinzugefügt}}.',
          itemRemoved:
            '{number, plural, one {Artikel aus dem Einkaufswagen entfernt} other {Artikel aus dem Einkaufswagen entfernt}}.',
          quantityChaged: 'Die Menge hat sich geändert.',
        },
      },
      components: {
        appBartActionCart: 'Warenkorb',
        quantityField: {
          states: {
            quantityRounded: 'Die Menge wurde auf die nächste Packungsgröße gerundet.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Dein Einkaufswagen ist leer',
        },
        cartAfterAddModal: {
          continueShopping: 'mit dem Einkaufen fortfahren',
          goToCart: 'Zum Einkaufskorb gehen',
          title: 'Produkte wurden dem Warenkorb hinzugefügt',
          pcs: 'Stck',
        },
      },
    },
  },
};
export default messages;
