import { After, ensureReady } from '@jaredpalmer/after';
import queryBuilder from '@sprinx/query-builder';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import routes from './routes';

function Main({ data }: any) {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const appProps = {}; // restoreAppState('preloaded_app_props');

  return (
    <BrowserRouter>
      <App initialAppProps={appProps}>
        {/* @ts-ignore Component After is declared as cont without ability to override props. This maybe will fixed in AfterJs declarations in later versions.  */}
        <After data={data} routes={routes} transitionBehavior='blocking' />
      </App>
    </BrowserRouter>
  );
}

ensureReady(routes as any).then((data) => {
  queryBuilder.mlklSyncPreset();
  hydrate(<Main data={data} />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept();
}
