import canUseDOM from './@sprinx/react-after-razzle/canUseDom';

const apiUri: string = canUseDOM
  ? process.env.RAZZLE_API_URI || `${window.location.origin}/api`
  : process.env.RAZZLE_API_URI_SSR || process.env.RAZZLE_API_URI || '';

// eslint-disable-next-line no-console
console.log('canUseDOM', canUseDOM);
// eslint-disable-next-line no-console
console.log('apiUri', apiUri);
// eslint-disable-next-line no-console
console.log('process.env.RAZZLE_API_URI_SSR', process.env.RAZZLE_API_URI_SSR);
// eslint-disable-next-line no-console
console.log('process.env.RAZZLE_API_URI', process.env.RAZZLE_API_URI);
export default apiUri;
